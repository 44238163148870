import React from "react";

function Testimonials() {
  return (
    <section id="testimonials" className="font-poppins py-10 bg-gray-100 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl">
            Trusted by <span className="text-orange-500">1K+</span> Customers
          </h2>
        </div>

        <div className="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full sm:mt-12 lg:mt-20 lg:grid-cols-3 gap-x-6 xl:gap-x-12 gap-y-6">
          <div className="overflow-hidden bg-white rounded-md shadow">
            <div className="px-8 py-12">
              <blockquote className="mt-7">
                <p className="text-lg text-black">
                  “We couldn't be happier with the level of support and expertise provided by this computer supplier. Their team is always available to answer our questions and help us find the right solutions for our business needs.
                </p>
              </blockquote>
              <p className="text-base font-semibold tex-tblack mt-9">
                Suraj Katwal
              </p>
              <p className="mt-1 text-base text-gray-600">
                Digital Marketer
              </p>
            </div>
          </div>

          <div className="overflow-hidden bg-white rounded-md shadow">
            <div className="px-8 py-12">
              <blockquote className="mt-7">
                <p className="text-lg text-black">
                  “As a small business owner, I rely heavily on my computer systems to keep things running smoothly. Thanks to this computer supplier, I can rest easy knowing that I have access to top-quality products and services at an affordable price.”
                </p>
              </blockquote>
              <p className="text-base font-semibold tex-tblack mt-9">Aastha Sitaula</p>
              <p className="mt-1 text-base text-gray-600">Student</p>
            </div>
          </div>

          <div className="overflow-hidden bg-white rounded-md shadow">
            <div className="px-8 py-12">
              <blockquote className="mt-7">
                <p className="text-lg text-black">
                  “If you're in need of reliable and efficient computer products and services, look no further than this supplier. Their team is knowledgeable and friendly, and they always go above and beyond to ensure customer satisfaction.”
                </p>
              </blockquote>
              <p className="text-base font-semibold tex-tblack mt-9">
                Fusion Hub
              </p>
              <p className="mt-1 text-base text-gray-600">Reseller</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;

import React from "react";

function Services() {
  const elements = [
    {
      id: 1,
      name: "Computer Sales and Parts",
      content:
        "Browse our extensive selection of high-quality computers and computer parts, all at competitive prices.",
    },
    {
      id: 2,
      name: "Computer installation and setup",
      content:
        "Our experienced technicians will help you set up your new computer or other technology products quickly and efficiently.",
    },
    {
      id: 3,
      name: "Tender and Supply Services",
      content:
        "Need reliable and cost-effective technology products for your business? We can help with our comprehensive tender and supply services",
    },
    {
      id: 4,
      name: "Skills Training and Business Support",
      content:
        "Looking to expand your team's technology skills? We offer a range of training and support options to help your business succeed.",
    },
  ];
  return (
    <section id="services" className="font-poppins py-4 md:py-16 mb-6 px-3 md:px-0">
      <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center pb-8 space-y-3">
        <h1 className="text-3xl md:text-4xl uppercase font-bold text-center">
          Services
        </h1>
        <p className="w-28 h-3 bg-orange-500"></p>
      </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 md:gap-8 space-y-6 md:space-y-0">
          {elements.map(function (element) {
            const { id, name, content } = element;
            return (
              <div key={id} className="flex flex-col space-y-4 justify-center p-4 rounded-xl border-2 hover:border-orange-500 shadow-lg transition">
                <p className="text-xl font-semibold capitalize">{name}</p>
                <p>
                  {content}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Services;
